import React from 'react';
import './BookReviews.css';

const BookReviews = () => {
  return (
    <div className="book-reviews">
      <h1>Book Reviews</h1>
      <p>Discover our latest book reviews and recommendations.</p>
    </div>
  );
};

export default BookReviews;
