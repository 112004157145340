import React from 'react';
import './LandingPage.css';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <h1>Welcome to Aubrey Elle</h1>
      <p>Your gateway to insightful book reviews and more.</p>
    </div>
  );
};

export default LandingPage;
